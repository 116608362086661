<template>
	<div class="page_box">
		<div class="bom_box">
			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading">
					 
					<el-table-column prop="title" label="劳保用品"></el-table-column>
					<el-table-column prop="unit" label="单位" ></el-table-column>
					 
					<el-table-column prop="cus_rec_date" label="领取时间"></el-table-column>
					 
				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import bumen_cascader from '@/components/bumen/bumen_cascader.vue'; //部门选择
	import page from "@/components/page/page.vue"; //分页  统一的

	export default {
		components: {
			bumen_cascader,
			page,
		},
		data() {
			return {
				//提交数据
				forms: {
					keyword: '',
					position: '',
					name: '',
					start: '',
					end: '',
					status: '', //未领取 2已领取
				},
				//
				date_range: [],
				pickerOptions: {
					disabledDate(time) {
						// console.log('time', time)
						return (time.getTime()) > new Date().getTime();
					}
				},
				//
				list: [],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,

				pickStatusOptions: [{
					value: 1,
					title: '未领取'
				}, {
					value: 2,
					title: '已领取'
				}],

				yongpin_list: [], //
				//

				dialogVisible: false,


				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的


				times: [], //时间范围

				form_top: {
					lingqu: 0, //领取状态
				},



			};
		},
		computed: {},
		watch: {
			date_range(value) {
				//this.$log('日期范围', value)

				this.forms.start = value[0] || ''
				this.forms.end = value[1] || ''
			}
		},
		created() {
			this.page_title = this.$route.meta.title
			 
			this.get_list()
		},
		mounted() {

		},
		methods: {
		 
			do_reset() {

				this.get_list()
			},

			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			//统一的列表接口
			get_list() {
				console.log(this.forms)
				this.loading = true
				this.$api("receiveLabor", {
					// mobile:this.mobile
					userId:this.$route.query.id,
					...this.pages,
				}, "post").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						let list = res.data.list
						list.forEach(v => {
							v.cus_created_at = v.created_at.substr(0, 10)
							v.cus_rec_date = v.rec_date.substr(0, 10)
						})
						this.list = list
					} else {
						alertErr(res.msg)
					}
				});
			},


			confirm_bumen_cascader_value(value) {
				//this.$log('勾选部门数据', value)
				let departIds = '';

				if (typeof value[0] == 'object') {

					let last_id_arr = value.map(arr => arr[arr.length - 1])

					departIds = last_id_arr.join(',')

				} else {

					departIds = value[value.length - 1];

				}

				let departItems = JSON.stringify(value)

				//this.$log('departIds', departIds)
				//this.$log('departItems', departItems)

				this.bumen_position_info = {
						position: departIds
					},

					this.forms.position = departIds
			},


			// 设置已领取
			do_row_set_pick(row) {
				//this.$log('领取记录', row)

				this.confirm_pick(row.id)
			},
			do_row_batch_set_pick() {
				//this.$log('批量领取记录')

				let ids = this.check_list.map(v => v.id).join(',')
				if (!ids) {
					alertErr('请勾选数据')
					return
				}
				this.confirm_pick(ids)
			},
			confirm_pick(ids) {
				this.$api("receiveLabor", {
					ids: ids
				}, "post").then((res) => {
					alert(res)
					if (res.code == 200) {
						this.do_reset()
					}
				});
			}

		},
	};
</script>

<style scoped lang="less">
	.page_box {
		height: 100%;

		// background-color: red;
	 
		.bom_box {
			background: #FFFFFF;
			.bom_box_list {
				padding: 20px 20px 32px 20px;
				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>